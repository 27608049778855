.App {
  color: #f8e7d1;
  padding-left: 20px;
  padding-right: 20px;
  overflow: hidden;
}

Set the scrollbar width and color
::-webkit-scrollbar {
  width: 6px;
}

/* Track styles */
::-webkit-scrollbar-track {
  background-color: #202023;
}

/* Handle styles */
::-webkit-scrollbar-thumb {
  background-color: #800080;
  border-radius: 3px;
}

/* On hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #600060;
}

/* On dragging */
::-webkit-scrollbar-thumb:active {
  background-color: #400040;
}